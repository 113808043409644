<script setup lang="ts">
const props = defineProps<{
	label?: string
	caption?: string
	wrappable?: boolean
	draggable?: boolean
}>();
</script>

<template>
	<div
		class="data-item"
		:class="[
			{ 'data-item--wrappable': props.wrappable },
			{ 'data-item--draggable': props.draggable },
		]"
	>
		<div class="data-item__main">
			<div class="data-item__start" v-if="props.label">
				<span class="data-item__title">{{ props.label }}</span>
				<span class="data-item__caption" v-if="props.caption">{{ props.caption }}</span>
			</div>
			<div class="data-item__start" v-else-if="$slots.start">
				<slot name="start" />
			</div>
			<div class="data-item__end" v-if="$slots.end">
				<slot name="end" />
			</div>
			<slot />
		</div>
		<div class="data-item__draggable-indicator" v-if="props.draggable">
			<Icon name="menu" size="14" />
		</div>
	</div>
</template>
